import { ICompassStatusViewInput } from '../../javascript/controllers/compass/payments/status/view_input';
import { CompassFormPresenter } from '../../javascript/controllers/compass/payments/form/presenter';
import { ICompassFormViewInput } from '../../javascript/controllers/compass/payments/form/view_input';
import { ICompassStatusViewOutput } from '../../javascript/controllers/compass/payments/status/view_output';
import CompassFormRouter from '../../javascript/controllers/compass/payments/form/router';
import { ICompassFormViewOutput } from '../../javascript/controllers/compass/payments/form/view_output';
import { Payable } from '../../javascript/controllers/compass/model/payable';
import Web3Controller from '../../javascript/controllers/web3_controller';
import {
  CompassError,
  ErrorCode,
} from '../../javascript/controllers/compass/model/compass_error';
import ListController from '../../../lib/nakamoto/components/list/list_controller';
import { ICompassPayableFormViewInput } from '../../javascript/controllers/compass/payments/form/payable_form_view_input';
import { Token } from '../../javascript/controllers/compass/model/token';
import { ICompassPayableFormViewOutput } from '../../javascript/controllers/compass/payments/form/payable_form_view_output';
import { PresailMagnetWrapper } from '../../javascript/web3/presail_magnet_wrapper';
import { Web3Account } from '../../javascript/web3/magnet/types';
import { hideElement, showElement } from '../../javascript/helpers/animations_helpers';

interface ViewOutput {
  onAmountChange(event: Event): void;
  onSelectedPoolChange(event: Event): void;
}

export default class ContributionIntentFormController
  extends Web3Controller
  implements ICompassFormViewInput, ICompassPayableFormViewInput
{
  static targets = [
    'unsupportedChainIndicator',
    'selectedChainLogo',
    'selectedTokenName',
    'selectedTokenBalance',
    'selectTokenButton',
    'selectTokenButtonBalance',

    'poolSelector',
    'selectedPool',
    'minAmountLabel',
    'maxAmountLabel',
    'feeLabel',

    'form',
    'actionButton',
    'selectedTokenTicker',
    'amountInput',
    'amountErrorDisplay',
    'inputContainer',
    'maxAmountLink',
    'tokensAmount',
    'tokensAmountWrapper',
  ];
  declare readonly unsupportedChainIndicatorTarget: HTMLElement;
  declare readonly selectedChainLogoTargets: HTMLElement[];
  declare readonly selectedTokenNameTarget: HTMLElement;
  declare readonly selectedTokenBalanceTarget: HTMLSpanElement;
  declare readonly selectTokenButtonTargets: HTMLElement[];
  declare readonly selectTokenButtonBalanceTargets: HTMLElement[];
  declare readonly poolSelectorTarget: HTMLElement;
  declare readonly selectedPoolTarget: HTMLElement;
  declare readonly minAmountLabelTarget: HTMLElement;
  declare readonly maxAmountLabelTarget: HTMLElement;
  declare readonly feeLabelTarget: HTMLElement;
  declare readonly formTarget: HTMLFormElement;
  declare readonly actionButtonTarget: HTMLButtonElement;
  declare readonly selectedTokenTickerTarget: HTMLElement;
  declare readonly amountInputTarget: HTMLInputElement;
  declare readonly amountErrorDisplayTarget: HTMLSpanElement;
  declare readonly inputContainerTarget: HTMLElement;
  declare readonly maxAmountLinkTarget: HTMLElement;
  declare readonly tokensAmountTarget: HTMLElement;
  declare readonly tokensAmountWrapperTarget: HTMLElement;

  static values = {
    payable: Object,
    compassBaseUrl: String,
    backUrl: String,
    createPayableUrl: String,
    isAuthMethodEmail: Boolean,
    unitPrice: Number,
    poolCommissionRate: Number,
    poolId: Number,
  };

  declare readonly payableValue: Payable;
  declare readonly compassBaseUrlValue: string;
  declare readonly backUrlValue: string;
  declare readonly createPayableUrlValue: string;
  declare readonly isAuthMethodEmailValue: boolean;
  declare readonly unitPriceValue: number;
  declare poolCommissionRateValue: number;
  declare poolIdValue: number;

  static outlets = ['compass--status'];
  protected declare readonly compassStatusOutlet: ICompassStatusViewInput;

  compassPresenter?: ICompassFormViewOutput &
    ICompassPayableFormViewOutput &
    ICompassStatusViewOutput;

  async connect() {
    /*
     Need to wait for the next tick to have compassStatusOutlet instantiated.
     See https://stimulus.hotwired.dev/reference/lifecycle-callbacks#order-and-timing and
     https://github.com/hotwired/stimulus/issues/201
     */
    Promise.resolve().then(async () => {
      this.compassPresenter ||= new CompassFormPresenter({
        compassBaseUrl: this.compassBaseUrlValue,
        payable: this.payableValue,
        amount: Number(this.amountInputTarget.value),
        view: this,
        payableFormView: this,
        statusView: this.compassStatusOutlet,
        router: new CompassFormRouter({
          application: this.application,
          compassBaseUrl: this.compassBaseUrlValue,
          backUrl: this.backUrlValue,
        }),
      });
      await super.connect();
    });

    this.#handleFeeVisibility();
  }

  compassStatusOutletConnected(
    outlet: ICompassStatusViewInput,
    element: HTMLElement
  ) {
    this.compassPresenter?.onCompassStatusViewInputUpdated(outlet);
  }

  async onWeb3Initialized() {
    await super.onWeb3Initialized();
    await this.compassPresenter?.onWeb3Connect(
      new PresailMagnetWrapper(this.magnet!)
    );
  }

  onAccountConnected(account: Web3Account) {
    this.compassPresenter?.onWeb3ChainChange({
      id: this.magnet!.web3Account!.chain.id,
      name: this.magnet!.web3Account!.chain.name,
    });
    super.onAccountConnected(account);
  }

  onAccountDisconnected(account: Web3Account) {
    this.disableActionButton();
    super.onAccountDisconnected(account);
  }

  onSelectedTokenChange(event: Event): void {
    const selectedTokenInput = event.currentTarget as HTMLElement;

    this.compassPresenter?.onSelectedTokenChange(
      selectedTokenInput.dataset.tokenId
    );
  }

  async onActionButtonClick(event: Event): Promise<void> {
    event.preventDefault();
    this.compassPresenter?.onActionButtonClick();
  }

  async createPayable(): Promise<boolean> {
    return await this.createContributionIntent(this.poolIdValue.toString());
  }

  onMaxAmountClick(event: Event): void {
    event.preventDefault();
    this.amountInputTarget.value = this.maxAmountLinkTarget.dataset.value || '';
    this.compassPresenter?.onAmountChange(
      Number(this.maxAmountLinkTarget.dataset.value)
    );
    this.refreshTokensAmount();
  }

  // TODO: calculations should not be made in the view
  refreshTokensAmount(): void {
    if (!this.hasTokensAmountWrapperTarget) {
      return;
    }

    const pTags = this.tokensAmountWrapperTarget.querySelectorAll('p');

    // Check for invalid inputs
    if (
      this.unitPriceValue === undefined ||
      this.unitPriceValue <= 0 ||
      this.amountInputTarget.value === '' ||
      isNaN(this.amountInputTarget.value)
    ) {
      pTags[1].textContent = "0";

      pTags[0].classList.add('text-stroke');
      pTags[1].classList.add('text-stroke');

      pTags[0].classList.remove('text-gray-300');
      pTags[1].classList.remove('text-foreground');

      return;
    }

    // Calculate the token amount if inputs are valid
    const tokenAmount = (
      (Number(this.amountInputTarget.value) *
        (100 - this.poolCommissionRateValue)) /
      (this.unitPriceValue * 100)
    ).toFixed(2);

    // Update the second <p> element with the calculated token amount
    pTags[1].textContent = tokenAmount;

    if (tokenAmount == 0) {
      pTags[0].classList.add('text-stroke');
      pTags[1].classList.add('text-stroke');

      pTags[0].classList.remove('text-gray-300');
      pTags[1].classList.remove('text-foreground');
    } else {
      // Remove 'text-stroke' class and apply the respective classes when token amount > 0
      pTags[0].classList.remove('text-stroke');
      pTags[1].classList.remove('text-stroke');

      pTags[0].classList.add('text-gray-300');
      pTags[1].classList.add('text-foreground');
    }
  }

  /*
   * IViewInput implementation
   */
  enableForm(): void {
    this.formTarget
      .querySelectorAll(
        'input, button, [data-action="click->dialog--dialog#open"]'
      )
      .forEach((input: HTMLElement) => {
        input.removeAttribute('disabled');
        input.removeAttribute('aria-disabled');
        input.removeAttribute('readOnly');
      });
  }

  disableForm(): void {
    this.formTarget
      .querySelectorAll(
        'input, button, [data-action="click->dialog--dialog#open"]'
      )
      .forEach((input: HTMLElement) => {
        input.setAttribute('disabled', true);
        input.setAttribute('aria-disabled', true);
        input.setAttribute('readOnly', true);
      });
  }

  setActionButtonText(text: string): void {
    this.actionButtonTarget.innerHTML = text;
  }

  disableActionButton(): void {
    this.actionButtonTarget.disabled = true;
  }

  showSelectedToken(token: Token): void {
    this.unsupportedChainIndicatorTarget.classList.add('hidden');
    this.selectTokenButtonTargets.forEach((selectTokenButton) => {
      if (
        selectTokenButton.dataset.tokenId.toString() === token.id.toString()
      ) {
        this.selectedTokenNameTarget.textContent = token.ticker;
        this.selectedTokenBalanceTarget.textContent = `Balance: ${token.displayBalance!}`;
        selectTokenButton.classList.add('selected');
      } else {
        selectTokenButton.classList.remove('selected');
      }
    });
    this.selectedChainLogoTargets.forEach((selectedChainLogo) => {
      if (
        selectedChainLogo.dataset.chainId.toString() ===
        token.chain.id.toString()
      ) {
        selectedChainLogo.classList.remove('hidden');
      } else {
        selectedChainLogo.classList.add('hidden');
      }
    });
  }

  showTokenBalances(tokens: Array<Token>): void {
    this.selectTokenButtonTargets.forEach((selectTokenButton) => {
      const tokenBalance = tokens.find(
        (token) =>
          token.id.toString() === selectTokenButton.dataset.tokenId.toString()
      );
      if (tokenBalance) {
        selectTokenButton.setAttribute('data-balance', tokenBalance.balance);
      }
    });

    this.selectTokenButtonBalanceTargets.forEach(
      (selectTokenButtonBalanceTarget) => {
        const tokenBalance = tokens.find(
          (token) =>
            token.id.toString() ===
            selectTokenButtonBalanceTarget.dataset.tokenId.toString()
        );
        if (tokenBalance) {
          selectTokenButtonBalanceTarget.textContent =
            tokenBalance.displayBalance;
        }
      }
    );
  }

  showConnectedToUnsupportedChain(): void {
    this.selectedChainLogoTargets.forEach((selectedChainLogo) => {
      selectedChainLogo.classList.add('hidden');
    });
    this.unsupportedChainIndicatorTarget.classList.remove('hidden');
    this.selectedTokenBalanceTarget.textContent =
      'Change to a different network';
    this.hideMaxAmount();
    this.selectedTokenNameTarget.textContent = 'Unsupported';
    this.selectTokenButtonTargets.forEach((selectTokenButton) => {
      selectTokenButton.classList.remove('selected');
    });
  }

  /*
   ICompassPayableFormViewInput implementation
   */
  showValidAmountIndicator(): void {
    this.inputContainerTarget.classList.add('value-entered');
    this.inputContainerTarget.classList.remove('value-exceeded');
    hideElement(this.amountErrorDisplayTarget)
  }

  showInvalidAmountIndicator(errorMessage?: string): void {
    this.inputContainerTarget.classList.add('value-entered', 'value-exceeded');
    this.amountErrorDisplayTarget.textContent = errorMessage || '';
    showElement(this.amountErrorDisplayTarget)
  }

  showEmptyAmountIndicator(): void {
    this.inputContainerTarget.classList.remove(
      'value-entered',
      'value-exceeded'
    );
    hideElement(this.amountErrorDisplayTarget);
    this.inputContainerTarget.classList.remove(
      'value-entered',
      'value-exceeded'
    );
  }

  fillAmount(amount: string): void {
    this.amountInputTarget.value = amount;
  }

  showMaxAmount(maxAmount: string): void {
    this.maxAmountLinkTarget.dataset.value = maxAmount;
    this.maxAmountLinkTarget.textContent = `Max (${maxAmount})`;
    this.maxAmountLinkTarget.classList.remove('hidden');
  }

  hideMaxAmount(): void {
    this.maxAmountLinkTarget.classList.add('hidden');
  }

  showSelectedTokenTicker(): void {
    this.selectedTokenTickerTarget.classList.remove('hidden');
  }

  hideSelectedTokenTicker(): void {
    this.selectedTokenTickerTarget.classList.add('hidden');
  }

  /*
  ViewOutput implementation
   */
  async onAmountChange(_: Event): Promise<void> {
    this.compassPresenter?.onAmountChange(
      Number(this.amountInputTarget.value.replace(',', '.'))
    );

    this.refreshTokensAmount();
  }

  async onSelectedPoolChange(event: Event): void {
    const selectedPoolSelectorOption = event.currentTarget as HTMLElement;
    this.markPoolOptionSelected(selectedPoolSelectorOption);
    this.setMinAndMaxAmounts(
      selectedPoolSelectorOption.dataset.minAmount!,
      selectedPoolSelectorOption.dataset.maxAmountForUser!
    );
    this.compassPresenter?.onMinMaxAmountsChange(
      Number(selectedPoolSelectorOption.dataset.minAmount),
      selectedPoolSelectorOption.dataset.maxAmountForUser
        ? Number(selectedPoolSelectorOption.dataset.maxAmountForUser)
        : undefined
    );

    this.poolCommissionRateValue = Number(
      selectedPoolSelectorOption.dataset.fee
    );
    this.#handleFeeVisibility();
    this.poolIdValue = Number(selectedPoolSelectorOption.dataset.poolId);
    this.refreshTokensAmount();
    const pageUrl = '?pool_guid=' + selectedPoolSelectorOption.dataset.poolGuid;
    window.history.pushState('', '', pageUrl);
  }

  /*
   Private methods
   */
  setMinAndMaxAmounts(minAmount: string, maxAmount: string): void {
    // Sets the min and max permitted amounts in the amountTarget input
    this.amountInputTarget.setAttribute('min', minAmount);
    this.amountInputTarget.setAttribute('max', maxAmount);
  }

  private markPoolOptionSelected(
    selectedPoolSelectorOption: HTMLElement
  ): void {
    this.selectedPoolTarget.textContent = `Pool: ${selectedPoolSelectorOption
      .dataset.poolName!}`;
    this.poolListController?.select(selectedPoolSelectorOption.dataset.poolId!);

    this.minAmountLabelTarget.textContent =
      selectedPoolSelectorOption.dataset.minAmount;
    this.maxAmountLabelTarget.textContent =
      selectedPoolSelectorOption.dataset.maxAmount || '∞';
    this.feeLabelTarget.textContent = `Fee: ${selectedPoolSelectorOption.dataset.fee}%`;
  }

  #handleFeeVisibility(): void {
    if (this.poolCommissionRateValue === 0) {
      this.feeLabelTarget.classList.add('hidden');
    } else {
      this.feeLabelTarget.classList.remove('hidden');
    }
  }

  /*
  References to other controllers
   */
  private get poolListController(): ListController | undefined {
    const controllerElement = this.poolSelectorTarget.querySelector(
      '[data-controller~=list--list]'
    );
    if (controllerElement) {
      return this.application.getControllerForElementAndIdentifier(
        controllerElement,
        'list--list'
      ) as ListController;
    }
  }

  // TODO move the following methods to a separate class
  async createContributionIntent(poolId: string): Promise<boolean> {
    const requestBody = new URLSearchParams();
    requestBody.append('contribution_intent[pool_id]', poolId);
    requestBody.append(
      'contribution_intent[compass_payable_guid]',
      this.payableValue.guid!
    );
    requestBody.append(
      'contribution_intent[amount]',
      this.amountInputTarget.value
    );
    requestBody.append(
      'contribution_intent[contribution_mechanism]',
      this.payableValue.acceptedPaymentMethod
    );
    requestBody.append(
      'contribution_intent[platform_available_credits_amount]',
      this.payableValue.platformAvailableCreditsAmount!.toString()
    );
    let response = await fetch(this.createPayableUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.getCSRFToken(),
      },
      body: requestBody,
    });
    return response.ok;
  }

  // TODO: extract and reuse (same method defined in Compass)
  private getCSRFToken(): string {
    const csrfTokenElement = document.querySelector(
      'meta[name="csrf-token"]'
    ) as HTMLMetaElement;
    if (!csrfTokenElement) {
      throw new CompassError({
        code: ErrorCode.NoCSRFTokenError,
        message: 'CSRF token not found',
        userFriendlyMessage: 'Please refresh the page and try again.',
      });
    }
    return csrfTokenElement.content;
  }
}
